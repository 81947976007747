import * as CONST from "../../CONSTANTS";
import * as ECONST from "./ECONSTANTS";

const getMMDDYYY = dobObject => {
  let dob = Date.now();
  if (dobObject[CONST.MONTH] && dobObject[CONST.DAY] && dobObject[CONST.YEAR]) {
    dob = `${String(dobObject[CONST.MONTH][CONST.SELECT_VALUE]).padStart(
      2,
      "0"
    )}/${String(dobObject[CONST.DAY][CONST.SELECT_VALUE]).padStart(2, "0")}/${
      dobObject[CONST.YEAR][CONST.SELECT_VALUE]
    }`;
  }
  return dob;
};

const getCurrentDateInFormat = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
};

const sendLeadToSlinky = fData => {
  const reqid = window.reqid ? window.reqid : "";
  const trusted_form_token = window.trustedForm.id;
  const trusted_form_cert_url = document.getElementById(
    "xxTrustedFormCertUrl_0"
  ).value;
  const jornaya_lead_id = window.LeadiD.token;

  const _formData = fData[CONST.DATA];

  const gender = _formData[CONST.CN_GENDER];
  const firstName = _formData[CONST.CN_NAME][CONST.DATA][CONST.NAME_FNAME];
  const lastName = _formData[CONST.CN_NAME][CONST.DATA][CONST.NAME_LNAME];
  const phone = _formData[CONST.CN_PHONE];
  const email = _formData[CONST.CN_EMAIL];
  const dob = getMMDDYYY(_formData[CONST.CN_DOB][CONST.DATA]);
  const address = _formData[CONST.CN_ADDRESS][CONST.DATA][CONST.ADDRESS_STREET];
  const city = _formData[CONST.CN_ADDRESS][CONST.DATA][CONST.ADDRESS_CITY];
  const state =
    _formData[CONST.CN_ADDRESS][CONST.DATA][CONST.ADDRESS_STATE][
      CONST.SELECT_VALUE
    ];
  const zip_code = _formData[CONST.CN_ZIP_CODE];
  const user_agent = navigator.userAgent;
  const landing_page = window.location.host;
  const date_posted = getCurrentDateInFormat();

  const interested_benefits = _formData[CONST.CN_INTERESTED_BENEFITS];
  const purchase_intent = [_formData[CONST.CN_POLICY_TYPE]];

  //slinky istest
  let is_test = ECONST.SLINKY_IS_TEST;
  if (firstName == "test" || lastName == "test") {
    is_test = "true";
  }

  const GET_DATA = {
    lp_s5: reqid,
    trusted_form_cert_token: trusted_form_token,
    trusted_form_cert_url: trusted_form_cert_url,
    jornaya_lead_id: jornaya_lead_id,
    gender: gender,
    first_name: firstName,
    last_name: lastName,
    phone: phone,
    zip: zip_code,
    email: email,
    dob: dob,
    address: address,
    city: city,
    state: state,
    tcpa_agent: user_agent,
    landing_page: landing_page,
    lead_type: ECONST.SLINKY_LEAD_TYPE,
    date_posted: date_posted,
    ip_address: "0.0.0.0",
    order_id: ECONST.SLINKY_CAMPAIGN_ID,
    is_test: is_test,
    interested_benefits: interested_benefits,
    purchase_intent: purchase_intent
  };
  const searchParams = new URLSearchParams(GET_DATA);

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${ECONST.SLINKY_BEARER}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(
    `https://api.slinkydata.com/${ECONST.SLINKY_LEAD_TYPE}?${searchParams.toString()}`,
    requestOptions
  )
    .then(response => response.text())
    //.then(result => console.log(result))
    .catch(error => console.log("error", error));
};

export { sendLeadToSlinky };
