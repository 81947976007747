import React from "react";
import * as CONST from "../../CONSTANTS";
import BackButton from "./backButton";

export default function policyType({
  // fData,
  // setFData,
  handleNextClick,
  handleBackClick,
}) {
  const QUERY = "Individual or family policy?";
  const NEXT_COMPONENT = CONST.CN_INTERESTED_BENEFITS;
  const OPTIONS = [
    [CONST.POLICY_TYPE_INDIVIDUAL, CONST.POLICY_TYPE_INDIVIDUAL],
    [CONST.POLICY_TYPE_FAMILY, CONST.POLICY_TYPE_FAMILY],
  ]; //value, visible text

  const onNextClick = value => {
    let componentInfo = {};
    componentInfo[CONST.DATA] = value;
    componentInfo[CONST.NEXT_COMPONENT] = NEXT_COMPONENT;

    handleNextClick(componentInfo);
  };

  const onBackClick = () => {
    handleBackClick();
  };

  return (
    <div className="gender one-choice-buttons row">
      <div className="col-12 query">
        <h2>{QUERY}</h2>
      </div>
      <div className="col-12 inputs-wrap">
        {OPTIONS.map((option, i) => {
          return (
            <button
              key={i}
              className="btn btn-primary btn-lg"
              value={option[0]}
              onClick={() => onNextClick(option[0])}
            >
              {option[1]}
              <svg
                width="2em"
                height="1.4em"
                viewBox="0 0 16 16"
                className="bi bi-chevron-compact-right"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
                />
              </svg>
            </button>
          );
        })}
      </div>

      <div className="col-12">
        <BackButton onBackClick={onBackClick} />
      </div>
    </div>
  );
}
