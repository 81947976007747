/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";

import * as CONST from "../CONSTANTS";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FireEvent from "../components/fireEvent";
import ProgressCircle from "../components/wizard/progressCircle";

import PostingError from "../components/wizard/postingError";
import SendLead from "../components/wizard/sendLead";
import { initJornaya } from "../components/thirdParty/jornaya";
import { initTrustedform } from "../components/thirdParty/trustedform";
import { findGetParameter, isBrowser } from "../HELPERS";
import ZipCode from "../components/wizard/zipCode";
import { initMediaalphaPlacement } from "../components/thirdParty/mediaalpha";

import Gender from "../components/wizard/gender";
import DateOfBirth from "../components/wizard/dateOfBirth";
import Name from "../components/wizard/name";
import Address from "../components/wizard/address";
import Email from "../components/wizard/email";
import Phone from "../components/wizard/phone";
import InterestedBenefits from "../components/wizard/interestedBenefits";
import PolicyType from "../components/wizard/policyType";

export default function ApplyPage({ location }) {
  const FIRST_CN = CONST.CN_ZIP_CODE;
  const SECOND_CN = CONST.CN_NAME;
  const FORM_STATE_TEMPLATE = {};
  FORM_STATE_TEMPLATE[CONST.SHOW_COMPONENT] = FIRST_CN;
  FORM_STATE_TEMPLATE[CONST.DATA] = {};
  FORM_STATE_TEMPLATE[CONST.COMPONENT_FLOW] = [FIRST_CN];

  const [formData, setFormData] = useState(
    location && location.state && location.state[CONST.CN_ZIP_CODE]
      ? {
          [CONST.SHOW_COMPONENT]:
            location.state[CONST.CN_ZIP_CODE][CONST.NEXT_COMPONENT],
          [CONST.DATA]: {
            [CONST.CN_ZIP_CODE]: location.state[CONST.CN_ZIP_CODE][CONST.DATA],
          },
          [CONST.COMPONENT_FLOW]: [
            CONST.CN_ZIP_CODE,
            location.state[CONST.CN_ZIP_CODE][CONST.NEXT_COMPONENT],
          ],
        }
      : FORM_STATE_TEMPLATE
  );

  useEffect(() => {
    initTrustedform();

    if (findGetParameter("zip")) {
      let data = { ...formData };

      data[CONST.DATA][CONST.CN_ZIP_CODE] = findGetParameter("zip");
      data[CONST.SHOW_COMPONENT] = SECOND_CN;
      data[CONST.COMPONENT_FLOW].push(SECOND_CN);

      setFormData(data);
    }

    //check for incoming get parameters
    if (isBrowser()) {
      if (window.LeadiD === undefined) {
        initJornaya(); //init on index page might already be present if this is not new tab
      }

      let reqid = findGetParameter("reqid");
      if (reqid && reqid !== undefined) {
        window.reqid = reqid;
      }
      let pub_id = findGetParameter("pub_id");
      if (pub_id && pub_id !== undefined) {
        window.pub_id = pub_id;
      }
      let sub_id = findGetParameter("sub_id");
      if (sub_id && sub_id !== undefined) {
        window.sub_id = sub_id;
      }
      let s1 = findGetParameter("s1");
      if (s1 && s1 !== undefined) {
        window.s1 = s1;
      }
      let s2 = findGetParameter("s2");
      if (s2 && s2 !== undefined) {
        window.s2 = s2;
      }
      let s3 = findGetParameter("s3");
      if (s3 && s3 !== undefined) {
        window.s3 = s3;
      }
      let lp_request_id = findGetParameter("lp_reqid");
      if(lp_request_id && lp_request_id !== undefined) {
        window.lp_request_id = lp_request_id;
      }
    }
  }, []);

  useEffect(() => {
    let toShow = formData[CONST.SHOW_COMPONENT];

    if (toShow == CONST.CN_POSTING_ERROR) {
      initMediaalphaPlacement(formData, "thankyou");
    }

    if (toShow == CONST.CN_THANKS) {
      navigate("/thankyou", {
        state: { formData: formData },
        replace: true,
      });
    }
  }, [formData[CONST.SHOW_COMPONENT]]);

  const handleNextClick = componentInfo => {
    let data = { ...formData };

    data[CONST.DATA][data[CONST.SHOW_COMPONENT]] = componentInfo[CONST.DATA];
    data[CONST.SHOW_COMPONENT] = componentInfo[CONST.NEXT_COMPONENT];
    data[CONST.COMPONENT_FLOW].push(componentInfo[CONST.NEXT_COMPONENT]);

    setFormData(data);
    console.log(formData);
  };

  const handleBackClick = () => {
    let data = { ...formData };
    //delete formData[CONST.CHILDREN];
    let cnTrack = data[CONST.COMPONENT_FLOW];
    cnTrack.pop();
    data[CONST.SHOW_COMPONENT] = cnTrack[cnTrack.length - 1];
    setFormData(data);
  };

  return (
    <Layout layoutType={CONST.LAYOUT_WIZARD_PAGE}>
      <Seo title="Apply" />

      <ProgressCircle fData={formData} />

      {formData[CONST.SHOW_COMPONENT] === CONST.CN_PHONE && (
        <>
          <Phone
            fData={formData}
            setFData={setFormData}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
          />
          <FireEvent
            eventName="formStepChange"
            eventData={{ stepName: CONST.CN_PHONE }}
          />
        </>
      )}

      {formData[CONST.SHOW_COMPONENT] === CONST.CN_EMAIL && (
        <>
          <Email
            fData={formData}
            setFData={setFormData}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
          />
          <FireEvent
            eventName="formStepChange"
            eventData={{ stepName: CONST.CN_EMAIL }}
          />
        </>
      )}

      {formData[CONST.SHOW_COMPONENT] === CONST.CN_ADDRESS && (
        <>
          <Address
            fData={formData}
            setFData={setFormData}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
          />
          <FireEvent
            eventName="formStepChange"
            eventData={{ stepName: CONST.CN_ADDRESS }}
          />
        </>
      )}

      {formData[CONST.SHOW_COMPONENT] === CONST.CN_NAME && (
        <>
          <Name
            fData={formData}
            setFData={setFormData}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
          />
          <FireEvent
            eventName="formStepChange"
            eventData={{ stepName: CONST.CN_NAME }}
          />
        </>
      )}

      {formData[CONST.SHOW_COMPONENT] === CONST.CN_DOB && (
        <>
          <DateOfBirth
            fData={formData}
            setFData={setFormData}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
          />
          <FireEvent
            eventName="formStepChange"
            eventData={{ stepName: CONST.CN_DOB }}
          />
        </>
      )}

      {formData[CONST.SHOW_COMPONENT] === CONST.CN_GENDER && (
        <>
          <Gender
            fData={formData}
            setFData={setFormData}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
          />
          <FireEvent
            eventName="formStepChange"
            eventData={{ stepName: CONST.CN_GENDER }}
          />
        </>
      )}

      {formData[CONST.SHOW_COMPONENT] === CONST.CN_ZIP_CODE && (
        <>
          <ZipCode
            fData={formData}
            setFData={setFormData}
            handleNextClick={handleNextClick}
          />
          <FireEvent
            event="formStepChange"
            stepData={{ stepName: CONST.CN_ZIP_CODE }}
          />
        </>
      )}

      {formData[CONST.SHOW_COMPONENT] === CONST.CN_POLICY_TYPE && (
        <>
          {" "}
          <PolicyType
            fData={formData}
            setFData={setFormData}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
          />{" "}
          <FireEvent
            eventName="formStepChange"
            eventData={{ stepName: CONST.CN_POLICY_TYPE }}
          />
        </>
      )}

      {formData[CONST.SHOW_COMPONENT] === CONST.CN_INTERESTED_BENEFITS && (
        <>
          {" "}
          <InterestedBenefits
            fData={formData}
            setFData={setFormData}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
          />{" "}
          <FireEvent
            eventName="formStepChange"
            eventData={{ stepName: CONST.CN_INTERESTED_BENEFITS }}
          />
        </>
      )}

      {formData[CONST.SHOW_COMPONENT] === CONST.CN_SEND_LEAD && (
        <>
          <SendLead fData={formData} handleNextClick={handleNextClick} />
          <FireEvent
            eventName="formStepChange"
            eventData={{ stepName: CONST.CN_SEND_LEAD }}
          />
        </>
      )}

      {/* {formData[CONST.SHOW_COMPONENT] === CONST.CN_THANKS && (
        <>
          <ConnectStreamsModal fData={formData} />
          <Thanks fData={formData} />
          <FireEvent
            eventName="formStepChange"
            eventData={{ stepName: CONST.CN_THANKS }}
          />
        </>
      )} */}

      {formData[CONST.SHOW_COMPONENT] === CONST.CN_POSTING_ERROR && (
        <>
          <PostingError />
          <FireEvent
            eventName="formStepChange"
            eventData={{ stepName: CONST.CN_POSTING_ERROR }}
          />
        </>
      )}
    </Layout>
  );
}

/**
 *  How the memory structure looks
 *
 * {
 * 	COMPONENT_FLOW: ["CN_ZIP", "CN_GENDER", "CN_DOB"]
 * 	DATA: {CN_ZIP::"12345", CN_GENDER:"Female"}
 * 	SHOW_COMPONENT "CN_DOB"
 * }
 *
 *
 *
 *
 *
 */
